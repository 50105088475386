import { React, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { v4 as uuidv4 } from "uuid";
import { getCourtOrder } from "../http/courtApi";

const CourtOrder = () => {
  const initialState = {
    isTenant: false,
    hasTrustee: false,
    courtNum: "",
    obligors: [
      {
        id: uuidv4(),
        fio: "",
        dob: "",
        pob: "",
        adrsIsEqual: false,
        posti: "",
        region: "Магаданская обл.",
        city: "г. Магадан",
        street: "",
        house: "",
        flat: "",
      },
    ],
    prop_posti: "",
    prop_region: "Магаданская обл.",
    prop_city: "г. Магадан",
    prop_street: "",
    prop_house: "",
    prop_flat: "",
    demand_sum: "",
    fine: "0",
    fee: "",
    costs: "237.02",
    period_dfr: "",
    period_dto: "",
  };
  const [validated, setValidated] = useState(false);
  const [docTitle, setDocTitle] = useState("");
  const [formData, setFormData] = useState(initialState);

  const addObligor = () => {
    const prev = [
      ...formData.obligors,
      {
        id: uuidv4(),
        fio: "",
        dob: "",
        pob: "",
        adrsIsEqual: false,
        posti: "",
        region: "Магаданская обл.",
        city: "г. Магадан",
        street: "",
        house: "",
        flat: "",
        isTrustee: formData.hasTrustee,
      },
    ];
    setFormData((prevState) => ({
      ...prevState,
      obligors: prev,
    }));
  };

  const removeObligor = (id) => {
    setFormData((prevState) => ({
      ...prevState,
      obligors: formData.obligors.filter((obligor) => obligor.id !== id),
    }));
  };

  useEffect(() => {
    let fee = 0;
    const demand = Number(formData.demand_sum);

    // if (demand < 20000) {
    //   fee = (demand / 100) * 2;
    //   if (fee < 200) {
    //     fee = 200;
    //   }
    // }
    if (demand < 100000) {
      fee = 2000;
    }
    if (demand >= 100001 && demand <= 300000) {
      fee = (4000 + ((demand - 100000) / 100) * 3) / 2;
    }
    if (demand >= 300001 && demand <= 500000) {
      fee = (10000 + ((demand - 200000) / 100) * 2.5) / 2;
    }
    // if (demand > 1000000) {
    //   fee = 6600 + ((demand - 1000000) / 100) * 0.25;
    //   if (fee > 30000) {
    //     fee = 30000;
    //   }
    // }

    fee = String(fee.toFixed(2));

    setFormData((prevState) => ({
      ...prevState,
      fee,
    }));
  }, [formData.demand_sum]);

  const handleInputChange = (e, id) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleObligInputChange = (e, id) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      obligors: changeObligors(name, value, id),
    }));
  };

  const cleanForm = (e) => {
    setFormData(() => initialState);
  };

  const changeObligors = (name, value, id) => {
    const prev = [...formData.obligors];
    const index = formData.obligors.findIndex((obligor) => obligor.id === id);
    const edit = { ...formData.obligors[index] };

    if (name === "adrsIsEqual") {
      edit[name] = !edit[name];
      prev[index] = edit;
    } else {
      edit[name] = value;
      prev[index] = edit;
    }
    return prev;
  };

  const resetObligorsTrustee = () => {
    const prev = [...formData.obligors];
    const findIsTrusteeIndex = formData.obligors.findIndex((obligor) =>
      obligor.hasOwnProperty("isTrustee")
    );
    const findShareIndex = formData.obligors.findIndex((obligor) =>
      obligor.hasOwnProperty("share")
    );
    const editIsTrustee = { ...formData.obligors[findIsTrusteeIndex] };
    const editShare = { ...formData.obligors[findShareIndex] };
    if (findIsTrusteeIndex !== -1) {
      console.log(findIsTrusteeIndex);
      editIsTrustee["isTrustee"] = !editIsTrustee["isTrustee"];
      prev[findIsTrusteeIndex] = editIsTrustee;
    }
    if (findShareIndex !== -1) {
      console.log(findShareIndex);
      delete editShare["share"];
      prev[findShareIndex] = editShare;
    }
    return prev;
  };

  const checkAndSetData = () => {
    setFormData((prevState) => ({
      ...prevState,
      hasTrustee: !prevState.hasTrustee,
      isTenant: false,
      obligors: resetObligorsTrustee(),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    } else {
      const response = await getCourtOrder(formData);
      setDocTitle(response.data);
    }
    setValidated(true);
  };

  return (
    <div style={{ padding: "1rem" }}>
      <Container>
        <h1 className="my-5">Заявление о выдаче судебного приказа</h1>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <fieldset className="border rounded-3 p-3 mb-5">
            <legend className="float-none w-auto px-3">Данные должника</legend>
            {!formData.hasTrustee ? (
              <Form.Group className="mb-3" id="formCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Должник - наниматель"
                  name="isTenant"
                  value={formData.tenant}
                  onChange={() =>
                    setFormData((prevState) => ({
                      ...prevState,
                      isTenant: !prevState.isTenant,
                    }))
                  }
                />
              </Form.Group>
            ) : (
              ""
            )}
            <Form.Group className="mb-3" id="formisTrusteeCheckbox">
              <Form.Check
                type="checkbox"
                label="У должника имеется представитель"
                name="hasTrustee"
                value={formData.hasTrustee}
                onChange={() => checkAndSetData()}
              />
            </Form.Group>
            {formData.obligors.map((obligor, i) => (
              <div className="mt-5" key={obligor.id}>
                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    controlId={obligor.id + "formFio"}
                    xs={4}
                  >
                    <Form.Label>ФИО</Form.Label>
                    <Form.Control
                      type="text"
                      name="fio"
                      value={obligor.fio}
                      onChange={(e) => handleObligInputChange(e, obligor.id)}
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    controlId={obligor.id + "formDob"}
                    xs={2}
                  >
                    <Form.Label>Дата рождения</Form.Label>
                    <Form.Control
                      type="date"
                      name="dob"
                      value={obligor.dob}
                      onChange={(e) => handleObligInputChange(e, obligor.id)}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId={obligor.id + "formPob"}>
                    <Form.Label>Место рождения</Form.Label>
                    <Form.Control
                      type="text"
                      name="pob"
                      value={obligor.pob}
                      onChange={(e) => handleObligInputChange(e, obligor.id)}
                    />
                  </Form.Group>
                  {formData.obligors.findIndex((el) => el.id === obligor.id) <
                  1 ? (
                    <Form.Group as={Col} controlId="formCourtNum">
                      <Form.Label>Суд. участок</Form.Label>
                      <Form.Control
                        type="number"
                        name="courtNum"
                        value={formData.courtNum}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  ) : (
                    ""
                  )}
                  {formData.hasTrustee &&
                  formData.obligors.findIndex((el) => el.id === obligor.id) <
                    1 ? (
                    <Form.Group
                      as={Col}
                      controlId={obligor.id + "formObligShare"}
                    >
                      <Form.Label>Доля</Form.Label>
                      <Form.Select
                        type="text"
                        name="share"
                        value={obligor.share}
                        onChange={(e) => handleObligInputChange(e, obligor.id)}
                        required
                      >
                        <option>Выберите долю</option>
                        <option value={"1"}>1</option>
                        <option value={"1/2"}>1/2</option>
                        <option value={"1/3"}>1/3</option>
                        <option value={"1/4"}>1/4</option>
                        <option value={"1/5"}>1/5</option>
                      </Form.Select>
                    </Form.Group>
                  ) : (
                    ""
                  )}
                </Row>
                {!obligor.adrsIsEqual ? (
                  <>
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        controlId={obligor.id + "formObligZip"}
                      >
                        <Form.Label>Индекс</Form.Label>
                        <Form.Control
                          type="number"
                          name="posti"
                          min="000000"
                          max="999999"
                          value={obligor.posti}
                          onChange={(e) =>
                            handleObligInputChange(e, obligor.id)
                          }
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId={obligor.id + "formObligState"}
                      >
                        <Form.Label>Регион</Form.Label>
                        <Form.Control
                          type="text"
                          name="region"
                          value={obligor.region}
                          onChange={(e) =>
                            handleObligInputChange(e, obligor.id)
                          }
                          required
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId={obligor.id + "formObligCity"}
                      >
                        <Form.Label>Город</Form.Label>
                        <Form.Control
                          type="text"
                          name="city"
                          value={obligor.city}
                          onChange={(e) =>
                            handleObligInputChange(e, obligor.id)
                          }
                          required
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-4">
                      <Form.Group
                        as={Col}
                        controlId={obligor.id + "formObligStreet"}
                      >
                        <Form.Label>Улица</Form.Label>
                        <Form.Control
                          type="text"
                          name="street"
                          value={obligor.street}
                          onChange={(e) =>
                            handleObligInputChange(e, obligor.id)
                          }
                          required
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId={obligor.id + "formObligHouse"}
                      >
                        <Form.Label>Дом</Form.Label>
                        <Form.Control
                          type="text"
                          name="house"
                          value={obligor.house}
                          onChange={(e) =>
                            handleObligInputChange(e, obligor.id)
                          }
                          required
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId={obligor.id + "formObligFlat"}
                      >
                        <Form.Label>Квартира (офис, пом.)</Form.Label>
                        <Form.Control
                          type="text"
                          name="flat"
                          value={obligor.flat}
                          onChange={(e) =>
                            handleObligInputChange(e, obligor.id)
                          }
                        />
                      </Form.Group>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                <Form.Group className="mb-3" id="formadrsIsEqualCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Адрес регистрации совпадает с адресом собственности"
                    name="adrsIsEqual"
                    value={obligor.adrsIsEqual}
                    onChange={(e) => handleObligInputChange(e, obligor.id)}
                  />
                </Form.Group>

                <Row className="align-items-center">
                  <Col xs="auto">
                    {formData.obligors.findIndex(
                      (el) => el.id === obligor.id
                    ) !== 0 ? (
                      <Button
                        className="mb-2"
                        type="submit"
                        variant="danger"
                        onClick={() => removeObligor(obligor.id)}
                      >
                        Удалить
                      </Button>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            ))}
            {formData.obligors.length <= 3 && (
              <Button type="submit" variant="success" onClick={addObligor}>
                Добавить
                {formData.isTenant
                  ? " нанимателя"
                  : formData.hasTrustee
                  ? " представителя"
                  : " собственника"}
              </Button>
            )}
          </fieldset>
          <fieldset className="border rounded-3 p-3 mb-5">
            <legend className="float-none w-auto px-3">Данные имущества</legend>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formPropZip">
                <Form.Label>Индекс</Form.Label>
                <Form.Control
                  type="number"
                  name="prop_posti"
                  min="000000"
                  max="999999"
                  value={formData.prop_posti}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formPropState">
                <Form.Label>Регион</Form.Label>
                <Form.Control
                  type="text"
                  name="prop_region"
                  value={formData.prop_region}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formPropCity">
                <Form.Label>Город</Form.Label>
                <Form.Control
                  type="text"
                  name="prop_city"
                  value={formData.prop_city}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formPropStreet">
                <Form.Label>Улица</Form.Label>
                <Form.Control
                  type="text"
                  name="prop_street"
                  value={formData.prop_street}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formPropHouse">
                <Form.Label>Дом</Form.Label>
                <Form.Control
                  type="text"
                  name="prop_house"
                  value={formData.prop_house}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formPropFlat">
                <Form.Label>Квартира (офис, пом.)</Form.Label>
                <Form.Control
                  type="text"
                  name="prop_flat"
                  value={formData.prop_flat}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Row>
          </fieldset>
          <fieldset className="border rounded-3 p-3 mb-5">
            <legend className="float-none w-auto px-3">
              Расчет требований
            </legend>
            <Form.Group className="mb-3" controlId="formDemandSum">
              <Form.Label>Сумма требования</Form.Label>
              <Form.Control
                type="number"
                name="demand_sum"
                value={formData.demand_sum}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formFine">
              <Form.Label>Пени</Form.Label>
              <Form.Control
                type="number"
                name="fine"
                value={formData.fine}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formFee">
              <Form.Label>Гос. пошлина</Form.Label>
              <Form.Control
                readOnly
                name="fee"
                value={formData.fee}
                onChange={handleInputChange}
              />
              <Form.Text className="text-muted">
                Расчитано автоматически
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCosts">
              <Form.Label>Иные расходы</Form.Label>
              <Form.Control
                name="costs"
                value={formData.costs}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Row className="mb-2">
              <Form.Group as={Col} controlId="formPeriodDfr">
                <Form.Label>Период (с)</Form.Label>
                <Form.Control
                  type="date"
                  name="period_dfr"
                  value={formData.period_dfr}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formPeriodDto">
                <Form.Label>Период (по)</Form.Label>
                <Form.Control
                  type="date"
                  name="period_dto"
                  value={formData.period_dto}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Row>
          </fieldset>
          <Stack
            direction="horizontal"
            gap={2}
            className="justify-content-center mx-auto hstack gap-2"
          >
            <Button variant="primary" type="submit">
              Сформировать
            </Button>
            <Button variant="secondary" type="reset" onClick={cleanForm}>
              Очистить
            </Button>
            {docTitle !== "" ? (
              <Button
                href={process.env.REACT_APP_API_URL + "/" + docTitle}
                variant="success"
              >
                Скачать
              </Button>
            ) : (
              <></>
            )}
          </Stack>
        </Form>
      </Container>
    </div>
  );
};

export default CourtOrder;
