import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";

const Credential = () => {
  return (
    <Container className="my-5">
      <Nav className="justify-content-center" activeKey="/home">
        <Nav.Item>
          <Nav.Link href="to@gordiango.ru" style={{ fontSize: "12px" }}>
            Copyright © 2024 Konstantin Gordeev
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Container>
  );
};

export default Credential;
