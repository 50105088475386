import CourtOrder from "./pages/courtOrder";
import Main from "./pages/main";
import { COURT_ORDER_ROUTE, MAIN_ROUTE } from "./utils/consts";

export const authRoutes = [];

export const publicRoutes = [
  {
    path: MAIN_ROUTE,
    Component: Main,
  },
  {
    path: COURT_ORDER_ROUTE,
    Component: CourtOrder,
  },
];
